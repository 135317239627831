import React, {useState, useImperativeHandle, forwardRef} from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import config from '../config';
import menu_pc from '../assets/menu_pc.png'
import './menu.css';

import h5_log from '../assets/logo_black.png'
import back_icon from '../assets/back.png'
import nav_icon from '../assets/nav_arrow.png'
import scene_1 from '../assets/scene_1.png'
import scene_2 from '../assets/scene_2.png'
import funny from '../assets/funny.png'

const Menu = forwardRef((props,ref)=>{
  console.log("menu props:", props.setShakeHandle)
  const {setShakeHandle} = props
  const [showMenu, setShowMenu] = useState(false)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  const navigate = useNavigate();
  const location = useLocation();

  useImperativeHandle(ref, ()=>({
    showMenuHandle: ()=>{
      setShowMenu(true)
    }
  }))

  const jump = (type) => {
    setShowMenu(false)
    window.scrollTo(0, type * windowHeight)
    if(type == 2){
      if(setShakeHandle)
          setShakeHandle()
    }
  }

  // const joinHandle = ()=>{
  //   window.open(config.joinclubUrl, '_blank')
  // }

  return (
      <div className={'menuWrap '+ (showMenu ? 'show' : 'hidden')}>
        <div className={'menu_bg '+ (showMenu ? 'show' : 'hidden')} onClick={()=>setShowMenu(false)}></div>
        <div className={'menu pc '+ (showMenu ? 'show' : 'hidden')}>
          <img src={menu_pc} className='menu_pc' />
          <span className='menu_item back_button' onClick={()=>{jump(0)}}></span>
          <span className='menu_item about' onClick={()=>{jump(1)}}></span>
          <span className='menu_item token' onClick={()=>{jump(2)}}></span>
          <span className='menu_item buy' onClick={()=>{jump(3)}}></span>
          {/* <span className='menu_item join' onClick={joinHandle}></span> */}
        </div>
        <div className={'menu h5 '+ (showMenu ? 'show' : 'hidden')}>
          <div className='top'>
            <img src={h5_log} className='h5_logo'></img>
            <div className='back_button' onClick={()=>{jump(0)}}>
              <img src={back_icon} className='back_icon' alt='GO TO DOG IN VEGAS' />
              <span>GO TO DOG IN VEGAS</span>
            </div>
          </div>
          <div className='nav'>
            <div className='nav_item' onClick={()=>{jump(1)}}>
              <img src={nav_icon} ></img>
              <span>ABOUT</span>
            </div>
            <div className='nav_item' onClick={()=>{jump(2)}}>
              <img src={nav_icon} ></img>
              <span>TOKENOMICS</span>
            </div>
            <div className='nav_item' onClick={()=>{jump(3)}}>
              <img src={nav_icon} ></img>
              <span>BUY NOW</span>
            </div>
          </div>
          <div className='scene'>
            <div className='scene_item'>
              <img src={scene_1} ></img>
              <p>Dog in Vegas Gambling</p>
            </div>
            <div className='scene_item'>
              <img src={scene_2} ></img>
              <p>Dog in Vegas Playing</p>
            </div>
          </div>
          <div className='bottom'>
            <img src={funny} ></img>
            <p onClick={()=>{jump(0)}}>GO TO DOG IN VEGAS</p>
          </div>
        </div>
      </div>
  );
})

export default Menu;
