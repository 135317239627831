import React, {useEffect, useRef, useState} from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import Menu from '../pages/menu'
import logo from '../assets/logo_black.png';
import menu_icon from '../assets/menu_icon_black.png';
import banner from '../assets/token.png'
import banner_mobile from '../assets/token_mobile.png'
import back_icon from '../assets/back.png'
import welcome_banner from '../assets/welcome_banner.png'
import welcome_banner_palceholder from '../assets/welcome_banner_placeholder.png'
import token_logo from '../assets/token_logo.png'
import buy_title from '../assets/buynow_title.png'
import tokenomics_mobile from '../assets/tokenomics_mobile.png'
import './subpage.css';
import config from '../config';

const endpoint = config.endpoint
const initialInputMint = config.initialInputMint
const initialOutputMint = config.initialOutputMint

function Subpage(props) {
  // console.log('subpages propos: ', props)
  const location = useLocation();
  const navigate = useNavigate();
  let menuRef = useRef();
  const [type, setType] = useState('about')
  const [isShake, setIsShake] = useState(false)

  const showMenuHandle = ()=>{
    menuRef.current.showMenuHandle()
  }

 const initJupiter = ()=>{
    window.Jupiter.init({
        displayMode: "integrated",
        integratedTargetId: "integrated-terminal",
        // 👇 REPLACE THIS WITH YOUR OWN HTTP PROVIDER ENDPOINT
        endpoint: endpoint,
        formProps: {
          // Limit the input token to a single token
          // "fixedInputMint": true,
          // Limit the output token to a single token
          "fixedOutputMint": true,

          // Predefine the input amount
          // "initialAmount": "8888888800000",
          // Predefine the input token
          "initialInputMint": initialInputMint,
          // Predefine the output token
          "initialOutputMint": initialOutputMint,
          size: 'sm', // 'sm'
        },
    });
  }

  useEffect(()=>{
      setType(props.type)
      setTimeout(()=>{
        initJupiter()
      }, 10)
  }, [props.type])

  useEffect(()=>{
    if(props.shake){
      setIsShake(true)
    }
  }, [props.shake])

  return (
    <div className="Subpages">
      {/* <Menu ref={menuRef} setShakeHandle = {setShakeHandle}></Menu> */}
      { type === 'about' ?
        <section className='token_content'>
          <img src={banner} className='banner pc' alt='DOG IN VEGAS' />
          <img src={banner_mobile} className='banner h5' alt='DOG IN VEGAS' />
          <p>Total Supply</p>
          <div>
            <span>8,000,000,000</span>
          </div>
        </section> : null }
      { type === 'tokenomics' ?
        <section className='welcome_content'>
          <img src={welcome_banner} className={'banner pc '+(isShake ? ' shake':'')} alt='WELCOME DOG IN VEGAS' />
          <div className='banner h5'></div>
          <div className='placehoder pc'><img src={welcome_banner_palceholder} className='banner' alt='DOG IN VEGAS' /></div>
          <p>$DOGAS takes you on a thrilling adventure, from the dazzling casinos to the vibrant nightlife, infusing the unique charm of Las Vegas into the world of crypto, taking over the world of memes.</p>
        </section> : null }
        { type === 'buy' ?
          <section className='buy_content' >
            <img src={buy_title} className='buy_title'></img>
            <div className='operate_wrap'>
              <div className='desc'>
                <img src={token_logo} className='token_logo'></img>
                <p>Swap Your $SOL to $DOGAS</p>
              </div>
              <div className='terminal_wrap' id="integrated-terminal"></div>
            </div>
          </section>
        : null}
    </div>
  );
}

export default Subpage;
