import React ,{useRef} from 'react';
import logo from '../assets/logo_one.png';
import menu_icon from '../assets/menu_icon.png'
import t_icon from '../assets/logo_t.png'
import x_icon from '../assets/logo_x.png'
import './home.css';
import Menu from '../pages/menu'
import config from '../config';

function App() {
  let menuRef = useRef();

  const showMenuHandle = ()=>{
    menuRef.current.showMenuHandle()
  }

  return (
    <div className="App">
      <Menu ref={menuRef}></Menu>
      <footer>
        <a href={config.telegram} target='_blank' className='social'><img src={t_icon} alt='Telegram' /></a>
        <a href={config.x} target='_blank' className='social'><img src={x_icon} height={96} alt='X' /></a>
      </footer>
    </div>
  );
}

export default App;
