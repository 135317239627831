import React from 'react';
import {HashRouter, BrowserRouter, Route, Routes} from 'react-router-dom';
import Index from './pages/index.js'
import Home from './pages/home.js';
import Token from './pages/subpage.js';


const BasicRoute = () => (
    <BrowserRouter>
        <Routes>
            <Route exact path="/" element={<Index></Index>}/>
            {/* <Route path="/about" element={<Token/>}></Route>
            <Route path="/tokenomics" element={<Token/>}></Route>
            <Route path="/buy" element={<Token/>}></Route> */}
        </Routes>
    </BrowserRouter>
);


export default BasicRoute;
