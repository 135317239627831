import React, {useState} from 'react';
import Router from './router';

import menu_pc from './assets/menu_pc.png'
import './App.css';

function App() {
  const [showMenu, setShowMenu] = useState(false)

  return (
    <React.StrictMode>
        <Router />
    </React.StrictMode>
  );
}

export default App;
