export default {
    // swap 插件配置, 地址是插件例子地址
    endpoint: "https://example.solana-mainnet.quiknode.pro/123456/",
    initialInputMint: "DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263",
    initialOutputMint: "AZsHEMXd36Bj1EMNXhowJajpUXzrKcK57wW4ZGXVa7yR",

    //侧栏join the club地址,新页面打开
    // joinclubUrl: 'https://www.pepe.vip/',
    //home 社交媒体
    telegram: 'https://t.me/DoginVegas',
    x: 'https://x.com/Doginvegas'
};