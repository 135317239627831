import React ,{useRef, useState, useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import logo from '../assets/logo_one.png';
import logo_black from '../assets/logo_black.png';
import menu_icon from '../assets/menu_icon.png'
import menu_icon_black from '../assets/menu_icon_black.png';
import t_icon from '../assets/logo_t.png'
import x_icon from '../assets/logo_x.png'
import './home.css';
import Menu from './menu'
import config from '../config';
import back_icon from '../assets/back.png'
import './index.css';
import Home from './home'
import Subpage from './subpage';

function App() {
  let menuRef = useRef();
  const navigate = useNavigate();
  const [showHomeHeader, setShowHomeHeader] = useState(true)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  const [shake, setShake] = useState(false)
  const showMenuHandle = ()=>{
    menuRef.current.showMenuHandle()
  }

  const jump = () => {
    window.scrollTo(0, 0)
  }

  const setShakeHandle = () => {
    setShake(true)
  }

  const handleScroll = e => {
    if(window.scrollY < windowHeight){
      setShowHomeHeader(true)
    }else{
      setShowHomeHeader(false)
    }

    if((2 * windowHeight + 500) > window.scrollY && window.scrollY > (2 * windowHeight) && !shake){
      setShake(true)
    }
  }

  useEffect(()=>{

  }, [shake])

  useEffect(()=>{
    document.addEventListener('scroll', handleScroll, true)
  }, [])

  return (
    <div className="IndexPage" id='indexPage'>
      <Menu ref={menuRef} setShakeHandle = {setShakeHandle}></Menu>
      <header>
        <div className='inner'>
        {showHomeHeader ? 
        <div className='home_header'>
          <img src={logo} className='logo' alt='DOG IN VEGAS' />
          <img src={menu_icon} width={45} height={45} alt='MENU' onClick={showMenuHandle} className='menuButton'></img>
        </div>
        :
        <div className='subpage_header'>
          <div className='left'  onClick={jump}>
            <img src={back_icon} className='back_icon' alt='GO TO DOG IN VEGAS' />
            <span>GO TO DOG IN VEGAS</span>
          </div>
          <div className='right'>
            <img src={logo_black} className='logo' alt='DOG IN VEGAS' />
            <img src={menu_icon_black} alt='MENU' className='menuButton'  onClick={showMenuHandle}></img>
          </div>
        </div>
        }
        </div>
      </header>
      <Home></Home>
      <Subpage type="about"></Subpage>
      <Subpage type="tokenomics" shake = {shake}></Subpage>
      <Subpage type="buy"></Subpage>
    </div>
  );
}

export default App;
